
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="桩号" prop="deviceNo">
          <el-input placeholder="请输入桩号" v-model="formModel.deviceNo" style="width:300px;" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="涓流时间" prop="trickleTime">
          <el-input v-model="formModel.trickleTime" placeholder="请输入涓流时间" style="width:300px">
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <el-form-item label="系统音量" prop="systemVolume">
        <el-input type="number" v-model="formModel.systemVolume" placeholder="请输入系统音量" style="width:300px" min="0" max="15"></el-input>
        </el-form-item>
                <el-form-item label="面板灯开关" prop="panelSwitch">
            <el-switch
                v-model="formModel.panelSwitch"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="width:300px">
            </el-switch>
        </el-form-item>
                <el-form-item label="插拔断电" prop="powerOff">
            <el-switch
                v-model="formModel.powerOff"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="width:300px">
            </el-switch>
        </el-form-item>
        <el-form-item label="充满自停" prop="fullStop">
          <el-switch
              v-model="formModel.fullStop"
              active-color="#13ce66"
              inactive-color="#ff4949"
              style="width:300px">
          </el-switch>
        </el-form-item>
        <el-form-item label="心跳周期" prop="fullStop" min="5" max="60">
          <el-input v-model.number="formModel.heartbeatCycle" style="width:300px">
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingDeviceApi from "@/api/base/chargingDevice";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        templateId:"",
        priceList:[],
      },
      templateList: [],
      defaultPriceList:[],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if(self.formModel.trickleTime!=null&&self.formModel.trickleTime!=""){
             self.formModel.trickleTime = parseInt(self.formModel.trickleTime)
            }

            if(self.formModel.systemVolume!=null&&self.formModel.systemVolume!=""){
             self.formModel.systemVolume = parseInt(self.formModel.systemVolume)
            }

            return chargingDeviceApi.updateParm(self.formModel);
            
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {

  },
  mounted: function () {
    var self = this;

    (function () {
        return chargingDeviceApi.editParameter(self.businessKey);
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
</style>