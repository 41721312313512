
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :label-width="'150px'">
        <el-form-item label="桩名" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入充值金额" style="width:300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="桩号" prop="deviceNo">
        <el-input v-model="formModel.deviceNo" placeholder="请输入赠送金额" style="width:300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="充电桩二维码" prop="name">
          <img :src="wxQuCode" width="400"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingDeviceApi from "@/api/base/chargingDevice";
import userApi from "@/api/sys/user";
import QRCode from "qrcodejs2";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      templateList: [],
      defaultPriceList:[],
      qrCode: "",
      wxQuCode: ""
    };
  },
  methods: {
    downloadCodeImg(){
      // const qrcode = this.$refs.wxQuCode;
      // const img = qrcode.querySelector("img")
      // let imgSrc ="";
      // img.onload=()=>{
      //   imgSrc = qrcode.querySelector("img").src;
      // }

      var canvas=document.getElementsByTagName("canvas")[0].toDataURL("image/png");

      const alink = document.createElement("a");
      alink.setAttribute("href",canvas);
      alink.download ="充电桩二维码.jpg";
      alink.click();
    },
    closeDialog() {
      this.$emit("close", false);
    },
  },
  mounted: function () {
    var self = this;

    (function () {
        return userApi.chargingScanLogin(self.businessKey);
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.wxQuCode= jsonData.data

          // var qr = new QRCode(this.$refs.wxQuCode, {
          //   text: qrtext,
          //   width: 300,
          //   height: 300,
          //   colorDark: "#333333", //二维码颜色
          //   colorLight: "#ffffff", //二维码背景色
          //   correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
          // });
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
.wxQuCode{
  width: 200px;
  height: 200px;
}
</style>