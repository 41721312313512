
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
        <el-row :gutter="20" >
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">桩号：</span> 
                    <span class="data">{{formModel.name}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <span class="fontText">设备状态：</span> 
                <span class="data" v-if="formModel.online" style="color:green;">
                  在线
                </span>
                <span v-else>不在线</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">信号强度：</span> 
                <span class="data">{{formModel.gprs}}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">更新时间：</span> 
                    <span class="data">{{formModel.updateTime}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">设备温度：</span> 
                    <span class="data">{{formModel.temperature}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">电压：</span> 
                    <span class="data">{{formModel.voltage}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">充满自停预扣小时：</span> 
                    <span class="data">{{formModel.preDeductHour}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <span class="fontText">最后在线时间：</span> 
                    <span class="data">{{formModel.lastOnlineTime}}</span>
                </div>
            </el-col>
        </el-row>
        <h3>通道列表</h3>
        <el-divider content-position="left"></el-divider>
        <div class="channel-row">
          <div class="channel-col" v-for="(item,index) in formModel.channelStatusList" :key="index">
              <h3>{{item.no}}路</h3>
              <table>
                <tr>
                  <td>充电状态：</td>
                  <td>
                    <font v-if="item.charge=='1'" color="green">充电中</font>
                    <font v-else color="gray">空闲</font>
                  </td>
                </tr>
                <tr>
                  <td>通道状态：</td>
                  <td>
                    <el-select v-model="item.status" size="mini" style="width:80px;">
                      <el-option v-for="(item2,index2) in statusList" 
                      :label="item2.name"
                      :value="item2.value" :key="index2">
                      </el-option>
                    </el-select> 
                  </td>
                </tr>
              </table>
          </div>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingDeviceApi from "@/api/base/chargingDevice";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        templateId:"",
        priceList:[],
      },
      templateList: [],
      defaultPriceList:[],
      statusList:[{
        value:"1",
        name:"正常"
      },{
        value:"0",
        name:"故障"
      }],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

     chargingDeviceApi
        .updateChannelStatus(self.formModel)
        .then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });
            }
      });
    }
  },
  created() {
    // chargingDeviceApi.getTemplateList().then((response)=>{
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     this.templateList = jsonData.data;
    //   }
    // });
  },
  mounted: function () {
    var self = this;

    (function () {
        return chargingDeviceApi.editStatus(self.businessKey);
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}

.channel-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.channel-col {
  display: flex;
  flex-direction: column;
  width: 180px;
}

.channel-col h3{
  display: block;
  background: #f1f6fa;
  height: 30px;
  line-height: 30px;
}
</style>