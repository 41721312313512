import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/pageList", formData);
}

function list(){
    return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/list");
}

function create(){
    return request.get(constant.chargingServerUrl + "/chargingDeviceMerchant/create");
}
  
function edit(id){
return request.get(constant.chargingServerUrl + "/chargingDeviceMerchant/edit/" + id);
}

function add(formModel){
return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/add", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function update(formModel){  
return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/update", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function remove(id){
return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/delete/" + id);
}

function batchRemove(idList){
return request.post(constant.chargingServerUrl + "/chargingDeviceMerchant/batchDelete",idList,{
    headers: {
    "Content-Type": "application/json"
    }
});
}



export default {
    pageList,create,edit,add,update,remove,batchRemove,list
}