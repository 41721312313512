<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">充电桩管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/charging/chargingDevice">充电桩管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="桩号" prop="deviceNo">
        <el-input type="text" size="mini" v-model="queryModel.deviceNo"></el-input>
      </el-form-item>
      <el-form-item label="桩名" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="设备状态" prop="online">
        <el-select size="mini" v-model="queryModel.online">
          <el-option label="不在线" value="0"></el-option>
          <el-option label="在线" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="桩号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="桩名"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="添加时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="lastOnlineTime"
        label="最后在线时间"
        width="150"
      ></el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="{row}">
          <span v-if="row.online" style="color:green;">在线</span>
          <span v-else>不在线</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="templateName"
        label="收费模式"
        width="150"
      ></el-table-column>
      <el-table-column prop="validCode"  label="查看二维码"  width="100">
        <template slot-scope="{row}">
          <span><el-link type="primary" :underline="false" @click="handleQucode(row)">查看二维码</el-link></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="320">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="handleEdit(row)">设备管理</el-link>-
          <el-link type="warning" :underline="false" @click="handleParameter(row)">参数设置</el-link>-
          <el-link type="success" :underline="false" @click="handleStatus(row)">通道状态</el-link>-
          <el-link type="info" :underline="false" @click="handleMerchant(row)">分润配置</el-link>-
          <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>
          <template v-if="row.online">
          -<el-link type="info" @click="handleClose(row)">断开连接</el-link>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <chargingDevice-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></chargingDevice-detail>
    <chargingDevice-parameter
      v-if="showModal2"
      :businessKey="businessKey"
      :title="modalTitle2"
      @close="onDetailModalClose2"
    ></chargingDevice-parameter>
    <chargingDevice-status
      v-if="showModal3"
      :businessKey="businessKey"
      :title="modalTitle3"
      @close="onDetailModalClose3"
    ></chargingDevice-status>
    <chargingDevice-qucode
      v-if="showModal4"
      :businessKey="businessKey"
      :title="modalTitle4"
      @close="onDetailModalClose4"
    ></chargingDevice-qucode>
    <chargingDeviceMerchant-view
      v-if="showModal5"
      :businessKey="businessKey"
      :title="modalTitle5"
      @close="onDetailModalClose5"
    ></chargingDeviceMerchant-view>
  </div>
</template>
<script>
import chargingDeviceDetail from "./chargingDevice-detail";
import chargingDeviceMerchantView from "./chargingDeviceMerchant-view";
import chargingDeviceParameter from "./chargingDevice-parameter";
import chargingDeviceStatus from "./chargingDevice-status";
import chargingDeviceQucode from "./chargingDevice-qucode";
import chargingDeviceApi from "@/api/base/chargingDevice";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "chargingChargingDeviceList",
  data() {
    var self = this;

    return {
      queryModel: {
        deviceNo: "",
        name: "",
        online: null,
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      showModal3: false,
      modalTitle3: "",
      showModal4: false,
      modalTitle4: "",
      showModal5: false,
      modalTitle5: "",
      businessKey: "",
      selectedRow: {},
    };
  },
  methods: {
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("name", self.queryModel.name);

      if(self.queryModel.online!=null){
        formData.append("online", self.queryModel.online);
      }
      

      chargingDeviceApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleQucode(record) {
      this.modalTitle4 = "查看二维码";
      this.businessKey = record.id;
      this.showModal4 = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleParameter(record) {
      this.modalTitle2 = "参数设置";
      this.businessKey = record.id;
      this.showModal2 = true;
    },
    handleStatus(record) {
      this.modalTitle3 = "通道状态";
      this.businessKey = record.id;
      this.showModal3 = true;
    },
    handleMerchant(record) {
      this.modalTitle5 = "分润配置";
      this.businessKey = record.id;
      this.showModal5 = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingDeviceApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chargingDeviceApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleRefresh(record) {
      var self = this;

      self.loading = true;

      chargingDeviceApi.refresh(record.id).then(function (response) {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          // var index = self.tableData.indexOf(record);
          // self.tableData.splice(index, 1);
          self.changePage(self.pageIndex);

          self.$message({
            type: "success",
            message: "刷新成功!",
            showClose: true
          });
        }
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose2(refreshed) {
      //保存成功后回调
      this.showModal2 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose3(refreshed) {
      //保存成功后回调
      this.showModal3 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose4(refreshed) {
      //保存成功后回调
      this.showModal4 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose5(refreshed) {
      //保存成功后回调
      this.showModal5 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleClose(row){
      this.$confirm("是否确认断开连接?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingDeviceApi.closeConnection(row.deviceNo)
          .then(resp=>{
              var jsonData = resp.data;

              if (jsonData.result) {
                this.$message({
                  type: "success",
                  message: "关闭成功!"
                });

                setTimeout(()=>{
                  this.changePage(this.pageIndex);
                }, 1000);
              }
          });
        });
    }
  },
  created() {

  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "chargingDevice-detail": chargingDeviceDetail,
    "chargingDevice-parameter": chargingDeviceParameter,
    "chargingDevice-status": chargingDeviceStatus,
    "chargingDevice-qucode": chargingDeviceQucode,
    "chargingDeviceMerchant-view":chargingDeviceMerchantView
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>