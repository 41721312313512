
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-table
        :data="formModel.tableData"
        v-loading="loading"
        stripe
        >
        <el-table-column
            prop="merchantRoleN"
            label="角色名称"
        ></el-table-column>
        <el-table-column
            prop="deviceName"
            label="桩名"
        ></el-table-column>
        <el-table-column   prop="merchantAccountId"  label="商家用户">
            <template slot-scope="scope">
                <el-form-item
                :prop="'tableData.' + scope.$index + '.merchantAccountId'"
                :rules="ruleValidate['merchantAccountId']"
                >
                <el-select 
                v-model="scope.row.merchantAccountId" 
                placeholder="请选择"  size="mini">
                    <el-option
                    v-for="result in merchantAccountList"
                    :key="result.id"
                    :label="result.name"
                    :value="result.id"
                    ></el-option>
                </el-select>
                </el-form-item>
            </template>
        </el-table-column>
        <el-table-column   prop="disProportion"   label="分销比例（%）">
            <template slot-scope="scope">
                <el-form-item
                :prop="'tableData.' + scope.$index + '.disProportion'"
                :rules="ruleValidate['disProportion']"
                size="mini"
                >
                    <el-input-number v-if="scope.row.roleType=='0'" :precision="2" :step="0.1" :min="0" placeholder="分销比例" v-model="scope.row.disProportion"  size="mini">
                    </el-input-number>
                </el-form-item>
            </template>
        </el-table-column>
        <el-table-column   prop="unitPrice"   label="电费单价（元）">
            <template slot-scope="scope">
                <el-form-item
                :prop="'tableData.' + scope.$index + '.unitPrice'"
                :rules="ruleValidate['unitPrice']"
                size="mini"
                >
                    <el-input-number v-if="scope.row.roleType=='1'" :precision="2" :step="0.1" :min="0" placeholder="电费单价" v-model="scope.row.unitPrice"  size="mini">
                    </el-input-number>
                </el-form-item>
            </template>
        </el-table-column>

        </el-table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingDeviceMerchantApi from "@/api/base/chargingDeviceMerchant";
import merchantAccountApi from "@/api/base/merchantAccount";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ],
            merchantAccountId:[{ required: true, message: "分润账户未选择", trigger: "blur" }]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
          id:"",
        name:"",
        parentId:"",
        tableData:[],
      },
      templateList: [],
      defaultPriceList:[],
      merchantAccountList:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            return chargingDeviceMerchantApi.update(self.formModel.tableData);
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {
    merchantAccountApi.list().then((response)=>{
      var jsonData = response.data;
      if (jsonData.result) {
        this.merchantAccountList = jsonData.data;
      }
    });
  },
  mounted: function () {
    var self = this;

    (function () {
        return chargingDeviceMerchantApi.edit(self.businessKey);
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel.tableData = jsonData.data; 
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-form-main{
    width: 300px;
}
.link-span{
    margin-right: 20px;
}
.el-form-item{
    margin-bottom: 25px;
}
.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.user-panel {
  margin: 10px auto;
}

.compact-table{
    .el-form-item {
        margin-bottom: 5px;
    }
}

// .el-form-item{
//     margin-bottom: 10px;
// }
</style>