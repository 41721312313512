
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1100px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="充电桩类型" prop="type">
          <el-select v-model="formModel.type" style="width:760px;" @change="typeChange">
            <el-option
              v-for="result in typeList"
              :key="result.value"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="桩号" prop="deviceNo">
          <el-input placeholder="请输入桩号" v-model="formModel.deviceNo" style="width:300px;">
            <!-- <el-button slot="append" icon="el-icon-search" @click="queryDeviceInfo">查询</el-button> -->
          </el-input>
        </el-form-item>
        <el-form-item label="桩名" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入桩名" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="所属站点" prop="stationId">
          <el-select-tree
            style="width:300px"
            :props="props"
            size="mediumn"
            :options="chargingStationResult"
            v-model="formModel.stationId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        
        <el-form-item label="收费模式" prop="templateId">
          <el-select v-model="formModel.templateId" clearable style="width:300px" @change="handleChangeTemplate">
            <el-option v-for="(item,index) in templateList" 
            :label="item.name" :value="item.id" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="formModel.type=='0'">
            <el-form-item label="充满自停时预扣" prop="preDeductHour">
              <el-input v-model.number="formModel.preDeductHour"  style="width:300px">
                <template slot="append">小时</template>  
              </el-input>
            </el-form-item>
            <el-form-item label="通道数" prop="numOfChannel">
              <el-input v-model.number="formModel.numOfChannel" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="超时凑整" prop="roundingMinute">
              <el-input v-model.number="formModel.roundingMinute" style="width:300px">
                <template slot="append">分钟</template> 
              </el-input>
              <font color="red">&nbsp;填-1为不凑整</font>
            </el-form-item>
        </div>
        <div v-else-if="formModel.type=='1'||formModel.type=='2'">
            <el-form-item label="通道数" prop="numOfChannel">
              <el-input v-model.number="formModel.numOfChannel" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="二维码前缀" prop="urlPrefix">
              <el-input v-model="formModel.urlPrefix" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="接口类型" prop="interfaceType">
              <el-input v-model ="formModel.interfaceType" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="充电类型" prop="chargingType">
              <el-input v-model="formModel.chargingType" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="充电功率" prop="chargingPower">
              <el-input v-model.number="formModel.chargingPower" style="width:300px">
              <template slot="append">千瓦（KW）</template>
              </el-input>
            </el-form-item>
        </div>
        <div v-if="formModel.type=='0'">
          <h3>收费模式</h3>
          <el-table 
          class="compact-table"
          :data="defaultPriceList" >
              <el-table-column prop="desc" label="档位" width="150"></el-table-column>
              <el-table-column prop="w" label="瓦数值">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input placeholder="功率下限" type="number" min="0"  v-model="scope.row.minPower"  size="mini" style="width:100px"></el-input>W&nbsp;&nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-input placeholder="功率上限" type="number" min="0"  v-model="scope.row.maxPower"  size="mini" style="width:100px"></el-input>W
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="充电时长" >
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input placeholder="每小时价格" type="number" min="0" v-model="scope.row.price"  size="mini" style="width:70%">
                      <template slot="append">元/小时</template>  
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
          </el-table>
        </div>
        <div v-else-if="formModel.type=='1'||formModel.type=='2'">
          <h3>收费模式</h3>
          <el-table 
          class="compact-table"
          :data="formModel.defaultPricePeriodList" >
              <el-table-column prop="startTime" label="起始时间">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultPricePeriodList.' + scope.$index + '.startTime'"
                    :rules="ruleValidate['startTime']"
                  >
                    <el-time-select
                      size="mini"
                      editable=false
                      v-model="scope.row.startTime"
                      placeholder="选择时间"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30'
                      }">
                    </el-time-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="结束时间">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultPricePeriodList.' + scope.$index + '.endTime'"
                    :rules="ruleValidate['endTime']"
                  >
                    <el-time-select
                      size="mini"
                      editable=false
                      v-model="scope.row.endTime"
                      placeholder="选择时间"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30'
                      }">
                    </el-time-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="electricityPrice" label="电价（元/小时）" >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultPricePeriodList.' + scope.$index + '.electricityPrice'"
                    :rules="ruleValidate['electricityPrice']"
                  >
                    <el-input-number placeholder="电价" :min="0" :step="0.1" v-model="scope.row.electricityPrice"  size="mini">
                      <template slot="append">元/小时</template>  
                    </el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="servicePrice" label="服务费" >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'defaultPricePeriodList.' + scope.$index + '.servicePrice'"
                    :rules="ruleValidate['servicePrice']"
                  >
                    <el-input-number placeholder="服务费" :min="0" :step="0.1" v-model="scope.row.servicePrice"  size="mini">
                      <template slot="append">元/小时</template>  
                    </el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" fixed="right">
                <template slot-scope="{row}">
                  <el-form-item>
                    <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                    <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                  </el-form-item>
                </template>
              </el-table-column>

              <div slot="empty">
                <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
              </div>
          </el-table>
        </div>
      </el-form>
        <div class="el-table__empty-block" v-if="formModel.defaultPricePeriodList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingDeviceApi from "@/api/base/chargingDevice";
import chargingStationApi from "@/api/base/chargingStation";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            type: [
                { required: true, message: "充电桩类型不能为空", trigger: "blur" }
            ],
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            stationId: [
                { required: true, message: "所属站点不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ],
            preDeductHour:[
              {required: true, message: "预扣小时不能为空", trigger: "blur" }
            ],
            numOfChannel:[
              {required: true, message: "通道数不能为空", trigger: "blur" }
            ],
            urlPrefix:[
              {required: true, message: "二维码前缀不能为空", trigger: "blur" }
            ],
            interfaceType:[
              {required: true, message: "接口类型不能为空", trigger: "blur" }
            ],
            chargingType:[
              {required: true, message: "充电类型不能为空", trigger: "blur" }
            ],
            chargingPower:[
              {required: true, message: "充电功率不能为空", trigger: "blur" }
            ],
            startTime:[{ required: true, message: "起始时间不能为空", trigger: "blur" }],
            endTime:[{ required: true, message: "结束时间不能为空", trigger: "blur" }],
            electricityPrice:[{ required: true, message: "电价不能为空", trigger: "blur" }],
            servicePrice:[{ required: true, message: "服务费不能为空", trigger: "blur" }],
            roundingMinute:[
              {required: true, message: "超时凑整不能为空", trigger: "blur" },
              { type: 'number',min:-1,max:59, message: '分钟范围只能在-1到59'}
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        templateId:"",
        priceList:[],
        type:"",
        urlPrefix:"",
        interfaceType:"",
        chargingType:"",
        chargingPower:"",
        defaultPricePeriodList:[],
        removeDefaultPricePeriodList:[],
      },
      templateList: [],
      defaultPriceList:[],
      chargingStationResult:[],
      treeData: [],
      typeList:[
        {
          value:"0",
          name:"自行车充电桩"
        },
        {
          value:"1",
          name:"直流汽车充电桩"
        },
        {
          value:"2",
          name:"交流汽车充电桩"
        },
      ],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if(self.formModel.type=="0"){
              self.formModel.priceList = self.defaultPriceList;
            }
            else{
              //增加转型,避免直接修改formModel
              var requestData = JSON.parse(JSON.stringify(self.formModel));

              var defaultPricePeriodList = requestData.defaultPricePeriodList;

              if(defaultPricePeriodList.length==0) {
                  this.$message.warning("收费模式不能为空!");
                  return;
              }
            }
            if (id == null || id.length == 0) {
              return chargingDeviceApi.add(self.formModel);
            } else {
              return chargingDeviceApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    typeChange(val){
      var self = this

      var formData = new FormData();

      formData.append("typeId", val);

      chargingDeviceApi.getTemplateList(formData).then((response)=>{
        var jsonData = response.data;
        if (jsonData.result) {
          self.templateList = jsonData.data;
        }
      });
    },
    handleChangeTemplate(val){
      var self = this;

      var formData = new FormData();

      formData.append("templateId", val);

      if(self.businessKey!=null&&self.businessKey!=""&&self.businessKey!="undefined"){
        formData.append("deviceId", self.businessKey);
      }
      else{
        formData.append("deviceId", "");
      }

      if(self.formModel.type!=null&&self.formModel.type!=""){
        if(self.formModel.type=="0"){
          chargingDeviceApi.getPriceList(formData).then((response)=>{
            var jsonData = response.data;
            if (jsonData.result) {
              self.defaultPriceList = jsonData.data;
            }
          });
        }
        else{
          chargingDeviceApi.getPricePeriodList(formData).then((response)=>{
            var jsonData = response.data;
            if (jsonData.result) {
              self.formModel.defaultPricePeriodList = jsonData.data;
            }
          });
        }
      }
    },
    handleAdd() {
        this.formModel.defaultPricePeriodList.push({
            startTime: "",
            endTime: "",
            electricityPrice:"",
            servicePrice:"",
        });
    },
    handleInsert(row) {
        var defaultPricePeriodList = this.formModel.defaultPricePeriodList;
        var index = defaultPricePeriodList.indexOf(row);

        if(index>=0){
            defaultPricePeriodList.splice(index+1,0,{
              startTime: "",
              endTime: "",
              electricityPrice:"",
              servicePrice:"",
            });
        }
    },
    handleDelete(row) {
        var defaultPricePeriodList = this.formModel.defaultPricePeriodList;

        defaultPricePeriodList.splice(defaultPricePeriodList.indexOf(row), 1);

        this.formModel.removeDefaultPricePeriodList.push(row);
    },
  },
  created() {
    // chargingDeviceApi.getTemplateList().then((response)=>{
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     this.templateList = jsonData.data;
    //   }
    // });

    var self = this;
    chargingStationApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.chargingStationResult = jsonData.data;
        }
      }
    });
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return chargingDeviceApi.create();
      } else {
        return chargingDeviceApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if(self.formModel.type!=null&&self.formModel.type!=""){
            self.typeChange(self.formModel.type);
          }
          
          self.handleChangeTemplate(self.formModel.templateId)    
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
     "el-select-tree": SelectTree
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
.input-form-main{
    width: 300px;
}
.link-span{
    margin-right: 20px;
}
.el-form-item{
    margin-bottom: 25px;
}
.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.user-panel {
  margin: 10px auto;
}

.compact-table{
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>