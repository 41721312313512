import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.chargingServerUrl + "/chargingDevice/pageList", formData);
}

function getTemplateList(formData){
    return request.post(constant.chargingServerUrl + "/chargingDevice/getTemplateList",formData);
}

function getPriceList(formData){
    return request.post(constant.chargingServerUrl + "/chargingDevice/getPriceList",formData);
}


function getPricePeriodList(formData){
    return request.post(constant.chargingServerUrl + "/chargingDevice/getPricePeriodList",formData);
}


function create(){
    return request.get(constant.chargingServerUrl + "/chargingDevice/create");
}
  
function edit(id){
return request.get(constant.chargingServerUrl + "/chargingDevice/edit/" + id);
}

function editParameter(id){
    return request.get(constant.chargingServerUrl + "/chargingDevice/editParameter/" + id);
}

function editStatus(id){
    return request.get(constant.chargingServerUrl + "/chargingDevice/editStatus/" + id);
}

function editCode(id){
    return request.get(constant.chargingServerUrl + "/chargingDevice/editCode/" + id);
}



function add(formModel){
return request.post(constant.chargingServerUrl + "/chargingDevice/add", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function update(formModel){  
return request.post(constant.chargingServerUrl + "/chargingDevice/update", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function updateParm(formModel){  
    return request.post(constant.chargingServerUrl + "/chargingDevice/updateParm", formModel,{
        headers: {
        "Content-Type": "application/json"
        }
    });
}


function updateChannelStatus(formModel){  
    return request.post(constant.chargingServerUrl + "/chargingDevice/updateChannelStatus", formModel,{
        headers: {
        "Content-Type": "application/json"
        }
    });
}




function remove(id){
    return request.post(constant.chargingServerUrl + "/chargingDevice/delete/" + id);
}

function batchRemove(idList){
    return request.post(constant.chargingServerUrl + "/chargingDevice/batchDelete",idList,{
        headers: {
        "Content-Type": "application/json"
        }
    });
}

function closeConnection(deviceNo){
    var formData = new FormData();
    formData.append("deviceNo", deviceNo);

    return request.post(constant.chargingServerUrl + "/chargingDevice/closeConnection", formData);
}


export default {
    pageList,create,edit,add,update,remove,batchRemove,getTemplateList,getPriceList,editParameter,editStatus,
    updateChannelStatus,updateParm,editCode,closeConnection,getPricePeriodList
}